/* This example requires Tailwind CSS v2.0+ */
import React, { useState, useEffect } from "react";
import BlogPeek from "./components/BlogPeek";

export default function Blog() {
  const [posts, setPosts] = useState();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    const run = async () => {
      const response = await fetch("./data/home.json");
      const posts = await response.json();
      setMounted(true);
      setPosts(posts.blog);
    };
    run();
  }, [mounted]);

  if (!posts) {
    return <></>;
  }

  return (
    <div>
      <BlogPeek title="Blog" posts={posts} />
    </div>
  );
}
