import React from "react";
import PropTypes from "prop-types";

export default function Header({ start, more }) {
  return (
    <div className="relative bg-gray-50 overflow-hidden">
      <div
        className="hidden sm:block sm:absolute sm:inset-y-0 sm:h-full sm:w-full"
        aria-hidden="true"
      >
        <div className="relative h-full max-w-7xl mx-auto">
          <svg
            className="absolute right-full transform translate-y-1/4 translate-x-1/4 lg:translate-x-1/2"
            width={404}
            height={784}
            fill="none"
            viewBox="0 0 404 784"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={85}
                height={50}
                patternUnits="userSpaceOnUse"
              >
                <g
                  id="signal"
                  stroke="none"
                  strokeWidth="1"
                  className="text-gray-100"
                  fill="currentColor"
                  fillRule="evenodd"
                >
                  <path
                    d="M0,0 L12,0 L12,6 L0,6 L0,0 Z M28,8 L40,8 L40,14 L28,14 L28,8 Z M42,0 L54,0 L54,6 L42,6 L42,0 Z M56,0 L68,0 L68,6 L56,6 L56,0 Z M56,8 L68,8 L68,14 L56,14 L56,8 Z M42,8 L54,8 L54,14 L42,14 L42,8 Z M42,24 L54,24 L54,30 L42,30 L42,24 Z M56,16 L68,16 L68,22 L56,22 L56,16 Z M70,16 L82,16 L82,22 L70,22 L70,16 Z M70,0 L82,0 L82,6 L70,6 L70,0 Z M28,32 L40,32 L40,38 L28,38 L28,32 Z M14,16 L26,16 L26,22 L14,22 L14,16 Z M0,24 L12,24 L12,30 L0,30 L0,24 Z M0,32 L12,32 L12,38 L0,38 L0,32 Z M14,32 L26,32 L26,38 L14,38 L14,32 Z M28,40 L40,40 L40,46 L28,46 L28,40 Z M14,40 L26,40 L26,46 L14,46 L14,40 Z M42,40 L54,40 L54,46 L42,46 L42,40 Z M56,32 L68,32 L68,38 L56,38 L56,32 Z M56,24 L68,24 L68,30 L56,30 L56,24 Z M70,32 L82,32 L82,38 L70,38 L70,32 Z M70,40 L82,40 L82,46 L70,46 L70,40 Z M14,24 L26,24 L26,30 L14,30 L14,24 Z M28,16 L40,16 L40,22 L28,22 L28,16 Z M14,8 L26,8 L26,14 L14,14 L14,8 Z M0,8 L12,8 L12,14 L0,14 L0,8 Z"
                    id="Combined-Shape"
                  ></path>
                </g>
              </pattern>
            </defs>
            <rect
              width={404}
              height={784}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute left-full transform -translate-y-3/4 -translate-x-1/4 md:-translate-y-1/2 lg:-translate-x-1/2"
            width={404}
            height={784}
            fill="none"
            viewBox="0 0 404 784"
          >
            <defs>
              <pattern
                id="5d0dd344-b041-4d26-bec4-8d33ea57ec9b"
                x={0}
                y={0}
                width={85}
                height={50}
                patternUnits="userSpaceOnUse"
              >
                <g
                  id="signal"
                  stroke="none"
                  strokeWidth="1"
                  className="text-gray-100"
                  fill="currentColor"
                  fillRule="evenodd"
                >
                  <path
                    d="M0,0 L12,0 L12,6 L0,6 L0,0 Z M28,8 L40,8 L40,14 L28,14 L28,8 Z M42,0 L54,0 L54,6 L42,6 L42,0 Z M56,0 L68,0 L68,6 L56,6 L56,0 Z M56,8 L68,8 L68,14 L56,14 L56,8 Z M42,8 L54,8 L54,14 L42,14 L42,8 Z M42,24 L54,24 L54,30 L42,30 L42,24 Z M56,16 L68,16 L68,22 L56,22 L56,16 Z M70,16 L82,16 L82,22 L70,22 L70,16 Z M70,0 L82,0 L82,6 L70,6 L70,0 Z M28,32 L40,32 L40,38 L28,38 L28,32 Z M14,16 L26,16 L26,22 L14,22 L14,16 Z M0,24 L12,24 L12,30 L0,30 L0,24 Z M0,32 L12,32 L12,38 L0,38 L0,32 Z M14,32 L26,32 L26,38 L14,38 L14,32 Z M28,40 L40,40 L40,46 L28,46 L28,40 Z M14,40 L26,40 L26,46 L14,46 L14,40 Z M42,40 L54,40 L54,46 L42,46 L42,40 Z M56,32 L68,32 L68,38 L56,38 L56,32 Z M56,24 L68,24 L68,30 L56,30 L56,24 Z M70,32 L82,32 L82,38 L70,38 L70,32 Z M70,40 L82,40 L82,46 L70,46 L70,40 Z M14,24 L26,24 L26,30 L14,30 L14,24 Z M28,16 L40,16 L40,22 L28,22 L28,16 Z M14,8 L26,8 L26,14 L14,14 L14,8 Z M0,8 L12,8 L12,14 L0,14 L0,8 Z"
                    id="Combined-Shape"
                  ></path>
                </g>
              </pattern>
            </defs>
            <rect
              width={404}
              height={784}
              fill="url(#5d0dd344-b041-4d26-bec4-8d33ea57ec9b)"
            />
          </svg>
        </div>
      </div>

      <div className="relative pt-6 pb-16 sm:pb-24">
        <main className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24">
          <div className="text-center">
            <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
              <span className="block xl:inline">We build and break</span>{" "}
              <span className="block text-blue-600 xl:inline">
                your software
              </span>
            </h1>
            <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
              Our team of hackers and software engineers specialize in the
              developing and testing the software that your business relies
              upon, allowing you to work with peace of mind.
            </p>
            <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
              <div className="rounded-md shadow">
                <a
                  onClick={start}
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 md:py-4 md:text-lg md:px-10"
                >
                  Get started
                </a>
              </div>
              <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
                <a
                  onClick={more}
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-blue-600 bg-white hover:bg-gray-50 md:py-4 md:text-lg md:px-10"
                >
                  Read more
                </a>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

Header.propTypes = {
  start: PropTypes.func,
  more: PropTypes.func,
};
