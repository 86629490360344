import React from "react";
import PropTypes from "prop-types";

const webFeatures = [
  {
    icon: "./icons/react.svg",
    title: "React",
    description:
      "Design and implement user interfaces rapidly through re-usable components that only render when data changes.",
  },
  {
    icon: "./icons/redux.svg",
    title: "Redux",
    description:
      "Managing a complex state across an application is not a problem with the correct architecture at its core.",
  },
  {
    icon: "./icons/angular.svg",
    title: "Angular",
    description:
      "Love Angular? You're not alone. Simplify development with a reliable framework where TypeScript is a first-class citizen.",
  },
  {
    icon: "./icons/babel.svg",
    title: "Babel",
    description:
      "Write JavaScript without worrying whether it will run in any browser. Keep up with the latest versions of JavaScript regardless of where your code runs.",
  },
  {
    icon: "./icons/webpack.svg",
    title: "Webpack",
    description:
      "Optimizing load times by bundling your JavaScript code and resources can be complicated. We can help you out.",
  },
  {
    icon: "./icons/javascript.svg",
    title: "JavaScript",
    description:
      "Implement complex features in your web app such as video calling, interactive maps, animated graphics and much more.",
  },
  {
    icon: "./icons/typescript.svg",
    title: "TypeScript",
    description:
      "Miss having a more strongly typed approach? Let's catch those subtle bugs before they deploy in production.",
  },
  {
    icon: "./icons/electron.svg",
    title: "Electron",
    description:
      "Natively running your web application on a desktop can be challenging. We can help by applying the best security practices and sandboxing techniques.",
  },
];

const programmingFeatures = [
  {
    icon: "./icons/nodejs.svg",
    title: "NodeJS",
    description:
      "Write serverless applications and focus on building your product rather than worrying how to scale it.",
  },
  {
    icon: "./icons/go.svg",
    title: "Golang",
    description:
      "Balance between security and near native performance for components and microservices in your critical path.",
  },
  {
    icon: "./icons/python.svg",
    title: "Python",
    description:
      "Automate tedious and repetitive tasks, enrich your data sources with new information and perform analysis.",
  },
  {
    icon: "./icons/c.svg",
    title: "C",
    description:
      "Write reasonably portable code for embedded systems, create bindings for a higher-level language to consume existing C code.",
  },
  {
    icon: "./icons/cplusplus.svg",
    title: "C++",
    description:
      "Use platform-specific assembly instructions or access the latest syscalls directly for reduced overhead and maximum performance.",
  },
  {
    icon: "./icons/objectivec.svg",
    title: "Objective C",
    description:
      "Build applications that run OS X and iOS. Create native bindings to consume Apple specific-features like TouchID in frameworks like React Native.",
  },
  {
    icon: "./icons/java.svg",
    title: "Java",
    description:
      "Take portability and security to another level with a language that executes cross-platform with automatic memory management.",
  },
  {
    icon: "./icons/lua.svg",
    title: "Lua",
    description:
      "Design your embedded system in a high-level language and execute it on any SoC that supports NodeMCU (ESP8266, ESP-12).",
  },
];

const deployFeatures = [
  {
    icon: "./icons/docker.svg",
    title: "Docker",
    description:
      "Build once and run anywhere. Take away repetitive, mundane configuration tasks and deploy your application anywhere.",
  },
  {
    icon: "./icons/kubernetes.svg",
    title: "Kubernetes",
    description: `Orchestrate your software deployment, scale your services on demand and securely manage your secrets.`,
  },
  {
    icon: "./icons/linux.svg",
    title: "Linux",
    description: `Setup your servers, configure the underlying configuration and kernel options for optimal performance.`,
  },
  {
    icon: "./icons/terraform.svg",
    title: "Terraform",
    description:
      "Write infrastructure as code. Manage infrastructure across clouds simply and consistently.",
  },
];

const databaseFeatures = [
  {
    icon: "./icons/redis.svg",
    title: "Redis",
    description:
      "Read and write data blazingly fast with an in-memory data store. Easily cache requests or files and decrease latency.",
  },
  {
    icon: "./icons/postgresql.svg",
    title: "PostgreSQL",
    description:
      "Turn complex processes into simple SQL transactions and use advanced data types that capture the domain perfectly.",
  },
  {
    icon: "./icons/mysql.svg",
    title: "MySQL",
    description:
      "Prefer working with an ACID database that you know and love? Not a problem! She was our first love and has a special place in our hearts.",
  },
  {
    icon: "./icons/mongodb.svg",
    title: "MongoDB",
    description:
      "Horizontally scale out your document storage and ensure your security complies with various standards.",
  },
];

const analyticsFeatures = [
  {
    icon: "./icons/tensorflow.svg",
    title: "TensorFlow",
    description:
      "Create high-performance data pipelines and robust machine learning models.",
  },
  {
    icon: "./icons/apachekafka.svg",
    title: "Kafka",
    description:
      "Scaling your real-time data analytics to multiple machines on demand.",
  },
  {
    icon: "./icons/grafana.svg",
    title: "Grafana",
    description:
      "Build analytics dashboards that help drive business development and monitor incidents.",
  },
  {
    icon: "./icons/rstudio.svg",
    title: "R Studio",
    description:
      "Mine your existing data collection and generate new insights to help accelerate growth and optimize processes.",
  },
];

const SegmentV2 = ({ content }) => {
  return (
    <div className="max-w-7xl mx-auto py-10 px-4 sm:px-6 lg:py-16 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
      <div>
        <h2 className="text-base font-semibold text-blue-600 uppercase tracking-wide">
          {content.subtitle}
        </h2>
        <p className="mt-2 text-3xl font-extrabold text-gray-900">
          {content.title}
        </p>
        {content.description.map((desc, i) => {
          return (
            <p key={i} className="mt-2 text-lg text-gray-500">
              {desc}
            </p>
          );
        })}
      </div>

      <div className="max-w-7xl mx-auto py-5 px-4 sm:px-6 lg:px-8 lg:col-span-2 w-full">
        <div className="gap-8 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 ">
          {content.features.map((feature) => (
            <div key={feature.icon} className="col-span-1 flex justify-left">
              <div key={feature.name} className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-14 w-14 rounded-md bg-gray-50 text-white">
                    <img
                      className="h-12 p-2"
                      src={feature.icon}
                      alt={feature.title}
                    />
                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                    {feature.title}
                  </p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-500">
                  {feature.description}
                </dd>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
SegmentV2.propTypes = {
  content: PropTypes.any,
};

export default function Features() {
  const webTech = {
    title: "Web technologies",
    subtitle: "10 years of experience in",
    description: [
      `We can help you realize your vision by quickly turning your ideas or designs into a web app.`,
      `We have seen the digital landscape evolve from
      vanilla JavaScript and jQuery to the featureful frameworks that we see
      today. Knowing which tools are best for the job is critical to
      delivering quality software on time.`,
      `We understand that users are
      increasingly becoming more demanding of the interfaces: getting the
      design and experience right determines the faith of your product.`,
    ],
    features: webFeatures,
  };
  const programming = {
    title: "Software",
    subtitle: "Everything you need in",
    description: [
      `Collaborate with our team to develop custom software solutions that
        drive value for your business.
        `,
      `Automate tasks that are repetitive and complex.
        We can help find areas for improvement, estimate the potential gains
        versus the development cost of automation and finally make it a reality.
        `,
      `Performance is king when it comes to reducing server costs and optimizing application load times.
        Let us identify and fix problematic code. Using an interpreted language? No problem.
        We can write bindings to native languages such as C and C++ to speed up performance significantly. `,
    ],
    features: programmingFeatures,
  };
  const deployment = {
    title: "Infrastructure",
    subtitle: "we take care of your",
    description: [
      `Let us set up new infrastructure or perform rigorous security checks on
        existing infrastructure.
        `,
      `Moving to the cloud is not an easy feat. Selecting a cloud provider and
        hardware is crucial to optimize the costs of your deployment.
        `,
      `Experience performance issues or trouble scaling your application? We
        can help there too.`,
    ],
    features: deployFeatures,
  };
  const database = {
    title: "Databases",
    subtitle: "we help store your data",
    description: [
      `We can help architect database solutions that won't keep you awake at night.`,
      `Selecting the right database solution as your foundation is critical to prevent hidden surprises.  Let us help design your solution from picking the right technology to designing your database schema.`,
    ],
    features: databaseFeatures,
  };
  const analytics = {
    title: "Analytics",
    subtitle: "we measure and improve",
    description: [
      `We have the necessary data engineering and data science knowledge to solve your analytics needs from start to finish,
      whether it's optimizing decoding and sampling for video pipelines, building deep learning models or deriving key insights for e-commerce shops.`,
    ],
    features: analyticsFeatures,
  };

  return (
    <div className="bg-white">
      <SegmentV2 content={webTech} />
      <SegmentV2 content={programming} />
      <SegmentV2 content={deployment} />
      <SegmentV2 content={database} />
      <SegmentV2 content={analytics} />
    </div>
  );
}
