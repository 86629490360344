import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Tag = ({ tag }) => {
  let color = "bg-blue-100 text-blue-800";
  /*
  switch (tag) {
    case "security":
      color = "bg-red-100 text-red-800";
      break;
    case "reverse engineering":
      color = "bg-green-100 text-green-800";
      break;
    case "software":
      color = "bg-blue-100 text-blue-800";
      break;
  }*/

  return (
    <span
      className={classNames(
        color,
        "inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium"
      )}
    >
      {tag}
    </span>
  );
};
Tag.propTypes = {
  tag: PropTypes.string,
};

export default function BlogPeek({ _ref, title, subtitle, posts }) {
  return (
    <div
      ref={_ref}
      className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8"
    >
      <div className="absolute inset-0">
        <div className="bg-white h-1/3 sm:h-2/3" />
      </div>
      <div className="relative max-w-7xl mx-auto">
        <div className="text-center">
          <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
            {title}
          </h2>
          <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
            {subtitle}
          </p>
        </div>
        <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
          {posts.map((post) => (
            <div
              key={post.title}
              className="flex flex-col rounded-lg shadow-lg overflow-hidden"
            >
              <div className="flex-shrink-0">
                <Link to={post.href}>
                  <img
                    className="h-48 w-full object-cover"
                    src={post.imageUrl}
                    alt=""
                  />
                </Link>
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <Tag tag={post.tag} />
                  <Link to={post.href}>
                    <span className="block mt-2">
                      <p className="text-xl font-semibold text-gray-900">
                        {post.title}
                      </p>
                      <p className="mt-3 text-base text-gray-500">
                        {post.description}
                      </p>
                    </span>
                  </Link>
                </div>
                <div className="mt-6 flex items-center">
                  <div className="flex-shrink-0">
                    <a href={post.author.href}>
                      <span className="sr-only">{post.author.name}</span>
                      <img
                        className="h-10 w-10 rounded-full"
                        src={post.author.imageUrl}
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-gray-900">
                      <a href={post.author.href} className="hover:underline">
                        {post.author.name}
                      </a>
                    </p>
                    <div className="flex space-x-1 text-sm text-gray-500">
                      <time dateTime={post.datetime}>{post.date}</time>
                      <span aria-hidden="true">&middot;</span>
                      <span>{post.readingTime} read</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
BlogPeek.propTypes = {
  _ref: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  posts: PropTypes.array,
};
