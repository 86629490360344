import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "./App.css";
import Nav from "./Nav";
import Home from "./screens/Home";
import Blog from "./screens/Blog";
import Footer from "./Footer";
import BlogPost from "./screens/components/BlogPost";
import Project from "./screens/Project";

function App() {
  return (
    <Router>
      <Nav />
      <Routes>
        <Route path="/blog/:id" element={<BlogPost />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/project" element={<Project />} />
        <Route path="/" element={<Home />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
