import React from "react";
import {
  CodeIcon,
  LockOpenIcon,
  PuzzleIcon,
  AcademicCapIcon,
} from "@heroicons/react/outline";

const features = [
  {
    name: "Secure Software Development",
    description:
      "We design and implement software with the best security practices in mind. Our testing processes will verify that it operates securely by subjecting it to wide range of adversarial attacks.",
    icon: CodeIcon,
  },
  {
    name: "Penetration Testing",
    description:
      "We can put your product or business to the test by simulating various attacks by one of our professional hackers. We can help verify your security by attempting to gain access to physical buildings or hacking into digital infrastructure.",
    icon: LockOpenIcon,
  },
  {
    name: "Reverse Engineering",
    description:
      "Found a malicious file? We can investigate the contents and help figure out what the attackers intententions.",
    icon: PuzzleIcon,
  },
  {
    name: "Security Training",
    description:
      "Prevention is better than cure. We offer specialized trainings for your employees and developers to operate at a higher security standard.",
    icon: AcademicCapIcon,
  },
];

export default function Services() {
  return (
    <div className="py-12 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            How we can help your business
          </p>
          <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
            Don&apos;t find exactly what you&apos;re looking for? <br /> Feel
            free to reach out and we&apos;ll let you know.
          </p>
        </div>

        <div className="mt-10">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
            {features.map((feature) => (
              <div key={feature.name} className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                    <feature.icon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                    {feature.name}
                  </p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-500">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
