/* This example requires Tailwind CSS v2.0+ */
import React from "react";

export default function Companies() {
  return (
    <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
      <p className="text-center text-sm font-semibold uppercase text-gray-500 tracking-wide">
        Building with start-ups and breaking into giants
      </p>
      <div className="mt-6 grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5">
        <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
          <img
            className="h-9"
            src="./companies/kerberosio.png"
            alt="Kerberos.io"
          />
        </div>
        <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
          <img className="h-9" src="./companies/exodus.svg" alt="Exodus" />
        </div>
        <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
          <img className="h-9" src="./companies/youtube.svg" alt="YouTube" />
        </div>
        <div className="col-span-1 flex justify-center md:col-span-2 md:col-start-2 lg:col-span-1">
          <img className="h-9" src="./companies/mapbox.svg" alt="Mapbox" />
        </div>
        <div className="col-span-2 flex justify-center md:col-span-2 md:col-start-4 lg:col-span-1">
          <img
            className="h-9"
            src="./companies/allienwork.png"
            alt="Allien.work"
          />
        </div>
      </div>
    </div>
  );
}
