/* This example requires Tailwind CSS v2.0+ */
import React, { useState, useEffect, useCallback, useRef } from "react";
import Header from "./components/Header";
import Services from "./components/Services";
import Companies from "./components/Companies";
import BlogPeek from "./components/BlogPeek";
import ContactUs from "./components/ContactUs";
import Features from "./components/Features";

export default function Home() {
  const [home, setHome] = useState();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    console.log("home running!");
    const run = async () => {
      const response = await fetch("./data/home.json");
      const home = await response.json();
      setMounted(true);
      setHome(home);
    };
    run();
  }, [mounted]);

  const contactEl = useRef(null);
  const onGetStarted = useCallback(() => {
    contactEl?.current?.focus();
  }, []);

  const blogEl = useRef(null);
  const onContactUs = useCallback(() => {
    blogEl?.current?.scrollIntoView();
  }, []);

  if (!home) {
    return <></>;
  } else {
    console.log("rendering home");
  }

  return (
    <>
      <Header start={onGetStarted} more={onContactUs} />
      <Companies />
      <Services />
      <Features />
      <BlogPeek
        _ref={blogEl}
        title="Blog"
        subtitle="Take a look at our previous work if you're not convinced yet!"
        posts={home?.blog?.slice(0, 3)}
      />
      <ContactUs _ref={contactEl} />
    </>
  );
}
