import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import SyntaxHighlighter from "react-syntax-highlighter";
import { nightOwl } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { Tweet } from "react-twitter-widgets";

export default function BlogPost() {
  const { id } = useParams();
  const [meta, setMeta] = useState();
  const [md, setMd] = useState();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    const run = async () => {
      const metaResp = await fetch(`/data/articles/${id}/meta.json`);
      const meta = await metaResp.json();
      setMeta(meta);

      const mdResp = await fetch(`/data/articles/${id}/index.md`);
      setMd(await mdResp.text());
      setMounted(true);
    };
    run();
  }, [mounted]);

  if (!mounted) {
    return <></>;
  }

  return (
    <div className="bg-white pt-16 pb-32">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
        <img
          className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5"
          src={meta.imageUrl}
        ></img>
        <h1 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl text-center">
          {meta.title}
        </h1>
        <ReactMarkdown
          skipHtml={false}
          rehypePlugins={[rehypeRaw]}
          remarkPlugins={[remarkGfm]}
          components={{
            h1: ({ node, ...props }) => (
              <h1
                className="p-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl"
                {...props}
              />
            ),
            h2: ({ node, ...props }) => (
              <h2
                className="p-4 leading-8 font-extrabold tracking-tight text-gray-900 sm:text-3xl"
                {...props}
              />
            ),
            h3: ({ node, ...props }) => (
              <h3
                className="p-4 leading-8 font-extrabold tracking-tight text-gray-900 sm:text-2xl"
                {...props}
              />
            ),
            p: ({ node, ...props }) => (
              <p className="m-3 tracking-tight text-gray-900" {...props} />
            ),
            blockquote: ({ node, ...props }) => (
              <p
                className="p-4 italic border-l-4 bg-neutral-100 text-neutral-600 border-neutral-500 quote"
                {...props}
              />
            ),
            ol: ({ node, ...props }) => (
              <ol className="ml-8 list-decimal" {...props} />
            ),
            ul: ({ node, ...props }) => (
              <ul className="ml-8 list-disc" {...props} />
            ),
            code({ node, inline, className, children, ...props }) {
              const match = /language-(\w+)/.exec(className || "");
              return !inline ? (
                <SyntaxHighlighter
                  // eslint-disable-next-line react/no-children-prop
                  children={String(children).replace(/\n$/, "")}
                  style={nightOwl}
                  language={match && match.length ? match[1] : "markdown"}
                  useInlineStyles={true}
                  {...props}
                />
              ) : (
                <code
                  className={
                    "bg-gray-100 text-gray-800 inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium"
                  }
                  {...props}
                >
                  {children}
                </code>
              );
            },
          }}
        >
          {md}
        </ReactMarkdown>
        <Tweet tweetId={meta.tweetId} options={{ align: "center" }} />
      </div>
    </div>
  );
}
BlogPost.propTypes = {
  entry: PropTypes.object,
};
